@font-face{
    font-family: Roboto; 
    src: url(../fonts/Roboto-Regular.ttf);
}

*{
    font-family: Roboto;
}
body,html{
    overflow-x: hidden;
}
header{
    padding: 20px;
}
.logo-img{
    transition: ease-in .3s;
}
.particles{
    position: fixed;
    width: 100%;
    z-index: -1;
}
.logo-img:hover{
    transform: scale(1.1);
}
canvas{
    z-index: -1 !important;
}
h1{
    margin-left: 20px;
    text-transform: uppercase;
}
.main-container{
    padding-bottom: 60px;
}
.form-container{
    padding: 20px;
    background-color: white;
    border-radius: 15px;
    border: solid 2px black;
}
.form-container > form{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

}
form > input{
    border-radius: 35px;
    border: solid 2px black;
    padding: 5px;
    color: gray;
}
form > select{
    border-radius: 35px;
    border: solid 2px black;
    padding: 5px;
    color: gray
}
.etiquetas{
    text-transform: uppercase;
    font-weight: bolds;
}
.boton-cotizar{
    background-color: black;
    color: white;
    text-transform: uppercase;
    transition: ease-in-out .2s;
    padding: 15px;
    font-size: 22px;
    margin-top: 10px;
}
.boton-cotizar:hover{
    transform: scale(1.02);
    cursor: pointer;
}
.seccion-cotiza{
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: center;
}
.resultados-p{
    font-size: 20px;
}
.footer-ingev{
    background-color: #191919;
    color: white;
    padding: 20px 10px;
   
}
.footer-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}
.footer-schedule,.footer-address,.follow{
    margin-left: 10px;
}
.footer-followus{
    display: flex;
}
.footer-address > p > a{
    text-decoration: none;
    color: white;
}
.filter-white{
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    width: 25px;
}
.social-icons{
    margin: 5px;
    display: flex;
    padding:15px;
    border-radius: 50%;
    background-color: #77b232;
    transition:  ease-out .2s;
}
.social-icons:hover{
    transform: scale(1.1);
}
.follow{
    margin: 10px;
}

@media only screen and (max-width: 900px) {
    .footer-followus{
        margin-bottom: 10px;
    }
    .form-container{
        margin: 10px;
    }
  }